<template>
  <div id="LaTenStoreDetail">
    <div class="MainContainer">
      <div class="breadRow"> {{ `亚马逊购物网 > ${storeType}` }} </div>
      <div class="StoreInfoBlock">
        <div class="left">
          <div class="storeImgContainer" v-if="storeDetail !== null">
            <img :src="require(`@/assets/store/Laten/${storeDetail.id}.jpg`)" alt="" />
          </div>
        </div>
        <div class="right">
          <div class="shopName">
            {{ storeName }}
          </div>
          <div class="priceBlock"> ￥{{ storePrice }} </div>
          <div class="tipLabel"> （如有颜色或者尺寸选项，请点击选择）留言： </div>
          <el-input
            class="textAreaInput"
            type="textarea"
            placeholder="如果有必要，请详细注明大小，颜色，型号等等。"
            v-model="textAreaMessage"
            resize="none"
            :rows="8"
          >
          </el-input>
          <div class="optionRow">
            <div class="optionRowLeft">
              数量:
              <el-select v-model="numberSelect" placeholder="请选择">
                <el-option
                  v-for="item in numOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="optionRowRight">
              <div class="addChartBtn" @click="addCart('LaTen')"> 添加到购物车 </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="MainContainer">
      <div class="describeRow">
        <div class="describeRowBlock"> 商品描述 </div>
      </div>
    </div>
    <div class="describeLine"></div>

    <div class="MainContainer">
      <div class="storeDescribeContainer">
        <div class="storeDescribeText"> {{ storeDescribe }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import base from './StoreDetailBase.js';
  export default {
    extends: base,
    name: 'LaTenStoreDetail',
  };
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>
